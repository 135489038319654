<template>
  <v-container
    class="alert-info-container drawer-detection-container"
    :class="drawer ? 'drawer-open' : 'drawer-closed'"
  >
    <v-card :loading="loading" max-width="800px">
      <v-toolbar flat v-if="selectedAlert">
        <v-toolbar-title :class="selectedAlert.alertType.color" class="alert-title">
          Alert {{selectedAlert.alertType.text}}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-0" v-if="selectedAlert && !loading">
        <div
          v-if="selectedAlert.userId"
          class="d-flex align-center user-id-container"
        >
          <v-text-field
            label="User Id"
            :value="selectedAlert.userId"
            readonly
          ></v-text-field>
          <v-btn
            v-if="alertUser"
            class="show-user-btn ml-2"
            x-small
            @click="openUserDialog(alertUser)"
          >
            Show User
          </v-btn>
        </div>
        <div
          v-if="selectedAlert.teamId"
          class="d-flex align-center team-id-container"
        >
          <v-text-field
            label="Team Id"
            :value="selectedAlert.teamId"
            readonly
          ></v-text-field>
          <router-link :to="`/teams/${selectedAlert.teamId}?tab=team-info`">
            <v-btn
              class="go-to-team-btn ml-2"
              x-small
            >
              Go to Team
            </v-btn>
          </router-link>
        </div>
        <v-text-field
          v-if="selectedAlert.traceId"
          label="Trace Id"
          :value="selectedAlert.traceId"
          readonly
        ></v-text-field>
        <v-text-field
          v-if="selectedAlert.viewerUserId"
          label="Viewer User Id"
          :value="selectedAlert.viewerUserId"
          readonly
        ></v-text-field>
        <v-text-field
          label="Message"
          :value="selectedAlert.message"
          readonly
        ></v-text-field>
        <v-text-field
          label="Created at"
          :value="moment(selectedAlert.createdAt).format(dateTimeMomentFormat)"
          readonly
        ></v-text-field>
        <v-text-field
          label="Updated at"
          :value="moment(selectedAlert.updatedAt).format(dateTimeMomentFormat)"
          readonly
        ></v-text-field>
        <v-textarea
          v-if="selectedAlert.description"
          label="Description"
          :value="selectedAlert.description"
          readonly
        ></v-textarea>
        <v-textarea
          v-if="selectedAlert.data"
          label="Data"
          :value="selectedAlert.data"
          readonly
        ></v-textarea>
      </v-card-text>
    </v-card>
    <UserDialog pCardTitle="User Info" ref="userDialog" :disabled="true" />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import Vue from "vue";
import {
  dateTimeMomentFormat, dismissAction, logAndExtractMessage,
} from "../../utils/utils";

export default {
  name: "AlertInfo",

  components: {
    UserDialog: () => import("../dialogs/UserDialog"),
  },

  data: () => ({
    moment,
    dateTimeMomentFormat,

    loading: false,
    alertUser: undefined,
  }),

  computed: {
    ...mapState("coreModule", [
      "drawer",
    ]),
    ...mapState("alertsModule", ["selectedAlert"]),
  },

  methods: {
    clearData() {
      this.$store.commit("alertsModule/updateSelectedAlert", undefined);
      this.alertUser = undefined;
    },

    openUserDialog(user) {
      this.$refs.userDialog.openDialog(user);
    },

    async loadAlert() {
      this.loading = true;

      try {
        await this.$store.dispatch("alertsModule/getAlertById", this.$route.params.id);
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }

      if (this.selectedAlert.userId) {
        try {
          this.alertUser = await this.$store.dispatch("usersModule/getUserById", this.selectedAlert.userId);
        } catch (e) {
          Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
        }
      }

      this.loading = false;
    },
  },

  mounted() {
    this.clearData();
    this.loadAlert();
  },
};
</script>
